<template>
  <div>
    <h6>Kirim Data Realisasi</h6>
    <br />
    <v-simple-table class="border">
      <template v-slot:default>
        <tbody>
          <tr>
            <th>Jumlah Data</th>
            <td>:</td>
            <td>{{ data_realisasi.jumlah_data }}</td>
          </tr>
          <tr>
            <th>Jumlah Terkirim</th>
            <td>:</td>
            <td>{{ data_realisasi.jumlah_terkirim }}</td>
          </tr>
          <tr>
            <th>Jumlah Belum Terkirim</th>
            <td>:</td>
            <td>{{ data_realisasi.jumlah_belum_terkirim }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="d-flex justify-content-end mt-5">
      <v-btn
        class="btn btn-primary"
        @click="handleSendDataToIBOSS()"
        :loading="isLoading"
        :disabled="data_realisasi.jumlah_belum_terkirim === 0"
      >
        <i class="fa-solid fa-paper-plane me-3"></i>
        Kirim Data Realisasi
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "KirimRealisasi",
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return this.$store.state.realisasi.isLoading;
    },
    data_realisasi() {
      return this.$store.state.realisasi.data_realisasi;
    },
  },
  methods: {
    handleSendDataToIBOSS() {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Data realisasi akan dikirim ke IBOSS",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Kirim!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("SendDataRealisasiToIBOSS", this.$route.params.id_ijin)
            .then(() => {
              this.$store.dispatch(
                "GetDataRealisasiByIdIjin",
                this.$route.params.id_ijin
              );
            });
        }
      });
    },
  },
  created() {
    this.$store.dispatch(
      "GetDataRealisasiByIdIjin",
      this.$route.params.id_ijin
    );
  },
};
</script>
